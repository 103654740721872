import React, { useContext, useEffect, useState } from 'react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
import { GetTransactionHistory } from './services/PaymentService';
import './TransactionHistory.css';
import { MyContext } from '../App';
import { useLoadingContext } from './LoadingProvider';
import { GetSettlementsDataByEvent } from './services/DjService';
import { DjTransactionsInfo } from './services/DjService';
import Collapsible from 'react-collapsible'; // Make sure to install this package
import { Button } from 'react-bootstrap'; // Ensure Bootstrap is installed and imported
import { MachanRevenue, RestaurantRevenue } from './Constants';

function TransactionHistory() {
    const { error, setError } = useContext(MyContext);
    const { errorMessage, setErrorMessage } = useContext(MyContext);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [filteredTransactionHistory, setFilteredTransactionHistory] = useState([]);
    const [distinctEventNames, setDistinctEventNames] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState('Today');
    const { setLoading } = useLoadingContext();
    const [selectedStatus, setSelectedStatus] = useState('All'); // Added state for selected status
    const [data, setData] = useState(null); // Store the API response data
    const [transactionInfo, setTransactionInfo] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleCollapsible = () => {
        setIsExpanded(!isExpanded);
    };

    const formatDate = (datetime) => {
        const date = new Date(datetime);
        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'long' }).toUpperCase();
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };

    const formatMonth = (datetime) => {
        const date = new Date(datetime);
        const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
        return month;
    };

    const groupedTransactions = filteredTransactionHistory.reduce((acc, transaction) => {
    const date = formatDate(transaction.modifiedOn);
    if (!acc[date]) acc[date] = [];
    acc[date].push(transaction);
    return acc;
        }, {});

    const monthlyTotals = Object.entries(groupedTransactions).reduce((acc, [date, transactions]) => {
        const month = formatMonth(transactions[0].modifiedOn);
        const totalAmount = transactions.reduce((total, transaction) => {
            return transaction.songStatus === 'Played' ? total + parseFloat(transaction.totalAmount) : total;
        }, 0);
        if (!acc[month]) acc[month] = 0;
        acc[month] += totalAmount;
        return acc;
    }, {});

    const [amountInfo, setamountInfo] = useState({
        totalAmount: null,
        settledAmount: null,
        remainingAmount: null,
        gstAmount: null, // To store the 18% GST amount
        amountExcludingGST: null, // To store the total amount - GST
    });
    useEffect(() => {
        const fetchTransactionData = async () => {
            try {
                const EventId = localStorage.getItem('eventId');
                console.log(`Event ID from localStorage: "${EventId}"`);

                if (!EventId) {
                    console.error('EventId is not provided');
                    return;
                }

                // Make the API request to fetch transaction data
                //const response = await fetch(`https://localhost:44369/Dj/DjTransactionsInfo?DjId=${Djid}`);
                const transactionData = await DjTransactionsInfo(EventId);
                //const transactionData = await response.json();

                // Log the transaction data to see its structure
                console.log('API response:', transactionData);

                // Calculate GST (18% of totalAmount)
                const totalAmount = transactionData.totalAmount || 0; // Ensure totalAmount is defined
                const gstAmount = totalAmount * 0.18;
                const amountExcludingGST = totalAmount - gstAmount;

                // Update the state with the fetched transaction data and the calculated values
                setamountInfo({
                    totalAmount,
                    //settledAmount: transactionData.settledAmount || null,
                    settledAmount: transactionData.remainingAmount != transactionData.totalAmount ? (amountExcludingGST.toFixed(2) * (localStorage.getItem('DjId') == 10032 ? MachanRevenue : RestaurantRevenue)).toFixed(2) - transactionData.remainingAmount : 0,
                    remainingAmount: transactionData.remainingAmount || null,
                    gstAmount,
                    amountExcludingGST,
                });

            } catch (error) {
                console.error('Error fetching transaction data:', error.message);
            }
        };

        fetchTransactionData();
    }, []);

    const totalTransactionCount = filteredTransactionHistory.length;

    //adding the codes --  task2.1
    // Calculate counts of transaction statuses
    // Filter pending and refunded transactions
    // Filter pending and refunded transactions
    const pendingTransactions = filteredTransactionHistory.filter(transaction => transaction.songStatus === 'Played');
    const refundedTransactions = filteredTransactionHistory.filter(transaction => transaction.songStatus === 'Refunded');

    const transactionCounts = filteredTransactionHistory.reduce((acc, transaction) => {
        if (transaction.songStatus === 'Played') {
            acc.played += 1;
        } else if (transaction.songStatus === 'Refunded') {
            acc.refunded += 1;
        } else if (transaction.songStatus === 'Rejected') {
            acc.rejected += 1;
        }
        return acc;
    }, { played: 0, refunded: 0, rejected: 0 });

    const calculateAmounts = (transaction) => {
        const lifetimeTransaction = transaction.lifetimeTransaction;
        const settledAmount = transaction.settledAmount;

        // Calculate lifetime transaction after deducting 18% GST
        const lifetimeTransactionAfterGST = lifetimeTransaction - (lifetimeTransaction * 0.18);

        // Calculate the remaining amount
        const remainingAmount = lifetimeTransactionAfterGST - settledAmount;

        return { lifetimeTransactionAfterGST, settledAmount, remainingAmount };
    };

    useEffect(() => {
        // Filter records based on the selected event and status
        const today = new Date();
        const filteredRecords = transactionHistory.filter((transaction) => {
            const transactionDate = new Date(transaction.modifiedOn);
            const isToday =
                transactionDate.getDate() === today.getDate() &&
                transactionDate.getMonth() === today.getMonth() &&
                transactionDate.getFullYear() === today.getFullYear();

            const matchesEvent = selectedEvent === 'All' ||
                (selectedEvent === 'Today' ? isToday : transaction.eventName === selectedEvent);

            const matchesStatus = selectedStatus === 'All' ||
                (selectedStatus === 'Played' && transaction.songStatus === 'Played') ||
                (selectedStatus === 'Pending' && transaction.songStatus === 'Pending') ||
                (selectedStatus === 'Rejected' && transaction.songStatus === 'Rejected');

            return matchesEvent && matchesStatus;
        });

        filteredRecords.sort((a, b) => b.id - a.id);
        setFilteredTransactionHistory(filteredRecords);
    }, [selectedEvent, selectedStatus, transactionHistory]);

    useEffect(async () => {
        setLoading(true);
        try {
            // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint to fetch transaction history
            var res = await GetTransactionHistory(localStorage.getItem('userId'));
            res.sort((a, b) => b.id - a.id);

            setTransactionHistory(res);
            setFilteredTransactionHistory(res); // Initialize with all records
            console.log(res);
            // Extract distinct event names from the data
            const eventNames = [...new Set(res.map((transaction) => transaction.eventName))];
            setDistinctEventNames(eventNames);
        } catch (error) {
            // Handle the error here and set error and error message as needed
            setError(true); // Assuming setError is a state variable to manage errors
            setErrorMessage(error.message); // Assuming setErrorMessage is a state variable to set error messages
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        // Filter records based on the selected event
        if (selectedEvent === 'Today') {
            const today = new Date();
            const filteredRecords = transactionHistory.filter((transaction) => {
                const transactionDate = new Date(transaction.modifiedOn);
                return (
                    transactionDate.getDate() === today.getDate() &&
                    transactionDate.getMonth() === today.getMonth() &&
                    transactionDate.getFullYear() === today.getFullYear()
                );
            });
            filteredRecords.sort((a, b) => b.id - a.id);
            setFilteredTransactionHistory(filteredRecords);

        } else if (selectedEvent !== '' && selectedEvent !== null && selectedEvent !== 'All') {
            const filteredRecords = transactionHistory.filter((transaction) => transaction.eventName === selectedEvent);
            filteredRecords.sort((a, b) => b.id - a.id);
            setFilteredTransactionHistory(filteredRecords);
        } else {
            // If no event is selected, show all records
            setFilteredTransactionHistory(transactionHistory);
        }
    }, [selectedEvent, transactionHistory]);

    // Calculate sum of total amounts in the filtered transaction history
    const totalAmountSum = filteredTransactionHistory.reduce((total, transaction) => {
        // Check if the songStatus is "Played"
        if (transaction.songStatus === "Played") {
            // If it is, add the totalAmount to the running total
            return total + parseFloat(transaction.totalAmount);
        }
        // If it's not "Played", just return the running total unchanged
        return total;
    }, 0);

    const handleEventFilterChange = (event) => {
        setSelectedEvent(event.target.value);
    };
    function formatDateTime(datetime) {
        const date = new Date(datetime);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    }
    function formatDateTime(datetime) {
        const date = new Date(datetime);

        // Get the components of the date
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
        const day = date.getDate().toString().padStart(2, '0');

        // Get the components of the time
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert 24-hour format to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        return `${month} ${day}, ${year}, ${hours}:${minutes} ${ampm}`;
    }


    function DateTimeDisplay({ datetimeString }) {
        const formattedDateTime = formatDateTime(datetimeString);
        return <div className="text-muted mb-0">{formattedDateTime}</div>;
    }


    return (
        <div className="transaction-history-container" >
            <div className='bg-music-background-ts-history'>
                <div className='main-heading' id="main">
                    <h2 >Transaction History</h2>
                    <div className="transaction-page-filter-container">
                        <select
                            id="eventFilter"
                            className="transaction-event-filter-select"
                            value={selectedEvent}
                            onChange={handleEventFilterChange}
                        >
                            <option value="All">All</option>
                            <option value="Today">Today</option>
                            {/* Populate dropdown with distinct event names */}
                            {distinctEventNames.map((eventName) => (
                                <option key={eventName} value={eventName}>
                                    {eventName}
                                </option>
                            ))}
                        </select>
                    </div>
                    
                </div>

                <div className="total-transaction-count">
                        <Collapsible
                            trigger={
                            <button className="collapsible-header">
                                <span className = "count1">Total Transactions : {pendingTransactions.length + refundedTransactions.length}</span>
                                    <span className="arrow">&#9654;</span>
                                </button>
                            }
                        >
                        <div className="transaction-count">
                                    Played : {pendingTransactions.length !== null ? pendingTransactions.length :0}
                                </div>

                        <div className="transaction-count">
                            Refunded : {refundedTransactions.length !== null ? refundedTransactions.length : '0'}
                                </div>
                            
                            {/* Show message if no transactions are pending or refunded */}
                        {pendingTransactions.length === 0 && refundedTransactions.length === 0 && (
                            <p className= "p"> No played or refunded transactions available.</p>
                            )}
                        </Collapsible>
                    </div>


                <div className="total-transaction-count">

                    {/* Collapsible for Pending and Refunded Transactions */}
                    <Collapsible
                        trigger={
                            <button className="collapsible-header">
                                <span className="count2">
                                    Total Amount(INR) : INR {amountInfo.amountExcludingGST !== null ?
                                        `${amountInfo.amountExcludingGST.toFixed(2)} + GST (${amountInfo.gstAmount !== null ? amountInfo.gstAmount.toFixed(2) : 'N/A'})` :
                                        'No transaction'}
                                </span>
                                <span className="arrow">&#9654;</span>
                            </button>
                        }
                    >
                        <div className="transaction-count">
                            Restaurant/DJs revenue : INR {amountInfo.amountExcludingGST !== null ?
                                        `${(amountInfo.amountExcludingGST.toFixed(2) * (localStorage.getItem('DjId') == 10032 ? MachanRevenue : RestaurantRevenue)).toFixed(2)}` :
                                        'No transaction'}
                            </div>   
                        <div className="transaction-count">
                            Settled Amount : INR {amountInfo.settledAmount !== null ? amountInfo.settledAmount : 'Not Settled'}
                            </div>
                        
                        <div className="transaction-count">
                                Remaining Amount : INR {amountInfo.amountExcludingGST !== null ?
                                        `${(amountInfo.amountExcludingGST.toFixed(2) * (localStorage.getItem('DjId') == 10032 ? MachanRevenue : RestaurantRevenue)).toFixed(2) - amountInfo.settledAmount}` :
                                        'N/A'}
                            </div>
                    </Collapsible>
                </div>


                <div className="transaction-cards">
                    {Object.entries(groupedTransactions).map(([date, transactions], index) => {
                        const month = formatMonth(transactions[0].modifiedOn);
                        const isFirstTransactionOfMonth = index === 0 || formatMonth(transactions[0].modifiedOn) !== formatMonth(Object.values(groupedTransactions)[index - 1][0].modifiedOn);
                        return (
                            <div key={date} className="date-group">
                                {isFirstTransactionOfMonth && (
                                    <div className="monthly-total-label">
                                        Earnings for {month}: ₹{monthlyTotals[month]}
                                    </div>
                                )}
                                <h3 className="date-heading">{date}</h3>
                                {transactions.map((transaction, index) => (
                                    <div key={index} className="transaction-card">

                                            <div className='history-page-left-content'>
                                                {
                                                (
                                                transaction.songName === null || transaction.songName === "undefined") &&
                                                transaction.micAnnouncement === null &&
                                                transaction.cake && 
                                                transaction.cake !== "" && 
                                                transaction.cake !== "null" && 
                                                transaction.cake !== null &&
                                                !transaction.cake.includes("null") ? (
                                                    <div style={{ overflowWrap: 'break-word', overflow: 'hidden' }}>
                                                            Cake Request: {transaction.cake}
                                                        </div>
                                                ) :
                                                transaction.micAnnouncement ? (
                                                    <>
                                                        
                                                        <div style={{ overflowWrap: 'break-word', overflow: 'hidden' }}>
                                                            {transaction.micAnnouncement}
                                                        </div>
                                                        {transaction.packages && (
                                                            <div className='packages-staff'>
                                                                {transaction.packages === "Silver" 
                                                                    ? "Silver - (Firegun, Mic announcement, Display name)" 
                                                                    : (transaction.packages === "Gold"
                                                                        ? (transaction.totalAmount > 2499 
                                                                            ? "Gold - Firegun, Display photo, Mic announcement" 
                                                                            : "Gold - Display photo, Mic announcement")
                                                                        : (transaction.packages === "VIP"
                                                                            ? "VIP - Champagne, Firegun, Display photo, Mic announcement" 
                                                                            : "")
                                                                    )}

                                                            </div>
                                                        )}
                                                    </>
                                                ) : (

                                                    <>
                                                        <div classname='song-row-song-name'>{transaction.songName}</div>
                                                        <div classname='song-row-artist-name'>{transaction.artistName}</div>
                                                    </>
                                                )}
                                            </div>

                                        <div className='transaction-page-middle-content'>
                                            <div className="transaction-amount-paid">
                                                ₹{transaction.totalAmount}
                                            </div>
                                            <div className='time-payid-block'>
                                                <div className="date-time-payment"><DateTimeDisplay datetimeString={transaction.paymentDateTime || transaction.createdOn} /></div>
                                                <div >Txn ID: {transaction.paymentId}</div>
                                            </div>
                                        </div>
                                        <div className='transaction-page-right-content'>
                                            {transaction.songStatus === 'Played' ?
                                                (<div className='btn-right-content btn-payment-green'>
                                                    {transaction.songStatus}
                                                </div>)
                                                :
                                                transaction.songStatus === 'Refunded' ?
                                                    (<div className='btn-right-content btn-payment-yellow'>
                                                        {transaction.songStatus}
                                                    </div>)
                                                    : transaction.songStatus === 'Rejected' ?
                                                        (<div className='btn-right-content btn-payment-red'>
                                                            {transaction.songStatus}
                                                        </div>)
                                                        : transaction.songStatus === 'Pending' ?
                                                            (<div className='btn-right-content btn-payment-yellow'>
                                                                {transaction.songStatus}
                                                            </div>)
                                                            : ''
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default TransactionHistory;
