import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { waiterRegistrationHelper } from '../Helpers/UserHelper';

const WaiterRegistration = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [waiterId, setWaiterId] = useState(localStorage.getItem('waiterId') || null);
    const [qrCodeUrl, setQrCodeUrl] = useState('');

    const generateQrCodeUrl = (waiterId) => {
        const eventId = localStorage.getItem('eventId') || null;
        const userId = localStorage.getItem('userId') || null;
        return `https://vibesync.in/songsearch?qrcode=true&eventId=42&userId=10114&referenceId=${waiterId}`;
    };

    const handleRegister = async () => {
        if (!name || !phone) {
            alert('Please provide both name and phone number');
            return;
        }

        try {
            const response = await waiterRegistrationHelper(name, phone);

            if (response.id) {
                const data = response;
                console.log(response);
                const id = data.id; // Assuming API returns an `id` field
                setWaiterId(id);
                localStorage.setItem('waiterId', id);
                setQrCodeUrl(generateQrCodeUrl(id));
                alert('Waiter registered successfully');
            } else {
                alert('Failed to register waiter');
            }
        } catch (error) {
            console.error('Error registering waiter:', error);
        }
    };

    const handleRetrieveId = async () => {
        if (!phone) {
            alert('Please provide a phone number');
            return;
        }

        try {
            console.log(phone);
            const response = await fetch(`User/GetUserIdByPhoneNumber?Phone=${phone}`, { method: 'GET' });
            const data = await response.json();
            console.log(data);
            if (data.Errors) {
                alert('Failed to retrieve waiter ID');
            } else {
                //const data = await response.json();
                console.log(data);
                const id = data; // Assuming API returns an `id` field
                setWaiterId(id);
                localStorage.setItem('waiterId', id);
                setQrCodeUrl(generateQrCodeUrl(id));
            }
        } catch (error) {
            console.error('Error retrieving waiter ID:', error);
        }
    };

    const handleAddNewRecord = () => {
        setName('');
        setPhone('');
        setWaiterId(null);
        setQrCodeUrl('');
        localStorage.removeItem('waiterId');
    };

    const downloadQrCode = () => {
        const canvas = document.getElementById('waiterQr');
        const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'WaiterQRCode.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <div className="waiter-registration">
            <h1>Staff Registration</h1>

            <div className="form-section">
                <h3>Register New Waiter</h3>
                <input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <button onClick={handleRegister}>Register Staff</button>
            </div>

            <div className="retrieve-section">
                <h3>Retrieve Staff ID</h3>
                <input
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <button onClick={handleRetrieveId}>Get Staff QR Code</button>
            </div>

            {qrCodeUrl && (
                <div className="qr-section">
                    <h3>Generated QR Code</h3>
                    <QRCode id="waiterQr" value={qrCodeUrl} size={290} level="H" includeMargin />
                    <button onClick={downloadQrCode}>Download QR Code</button>
                </div>
            )}

            <button onClick={handleAddNewRecord}>Add New Record</button>
        </div>
    );
};

export default WaiterRegistration;
