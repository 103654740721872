import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
    // Function to scroll to the top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smooth scrolling
        });
    };
  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
                      <Link to='/aboutus' onClick={scrollToTop}>How it works</Link>
                      <Link to='/termsofservice' onClick={scrollToTop}>Terms of Service</Link>
                      <Link to='/privacypolicy' onClick={scrollToTop}>Privacy Policy</Link>
                      <Link to='/paymentpolicy' onClick={scrollToTop}>Payments & Refunds</Link>
                      <Link to='/contactus' onClick={scrollToTop}>Contact Us</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
                  <div className='footer-link-items'>
                      <h2>Social Media</h2>
                      <a href="https://www.instagram.com/vibesync.in/?igshid=MW0wdXN3dzdxdW5qcQ%3D%3D" target="_blank" rel="noopener noreferrer">Instagram</a>
                  </div>
        </div>
      </div>
      <section class='social-media'>
        <div className='social-media-wrap'>
          <div className="centered-text">
            <small class='website-rights'>VibeSync © 2023</small>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
